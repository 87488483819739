"use client";
import Link from "next/link";
import Image from "next/image";
import { FaYoutube, FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaMapMarkerAlt, FaEnvelope, FaPhone, FaArrowUp } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { prodServerUrl } from "../../app/utils/globals";
import ScrollToTop from "../common/ScrollToTop/ScrollToTop";

export default () => {
  const [disable, setDisable] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm();

  const submitHandler = async ({ email }) => {
    try {
      if (email) {
        setDisable(true);
      }

      const data = await axios.post(`${prodServerUrl}/subscribe-us`, {
        email: email,
      });

      if (data.status === 200) {
        toast.success(
          "We have received your query. Your will be contacted soon."
        );
        setValue("email", "");

        setDisable(false);
      }
    } catch (e) {
      toast.error(e.message);
      setDisable(false);
    }
  };

  const footerNavs = [
    {
      label: "Quick Links",
      items: [
        {
          href: "/courses",
          name: "Courses",
        },
        {
          href: "/career",
          name: "We Are Hiring",
        },
        {
          href: "/about",
          name: "About",
        },
        {
          href: "/contact",
          name: "Contact Us",
        },
        {
          href: "/terms",
          name: "Terms and Conditions",
        },
        {
          href: "/policy",
          name: "Privacy Policy",
        },
        {
          href: "/refund",
          name: "Refund and Returns Policy",
        },
      ],
    },
  ];

  return (
    <footer className="relative bg-gradient-to-b from-gray-900 via-gray-900 to-black text-gray-200 overflow-hidden">
      {/* Decorative elements */}
      <div className="absolute top-0 right-0 w-64 h-64 bg-blue-500 rounded-full filter blur-3xl opacity-5 transform translate-x-1/2 -translate-y-1/2"></div>
      <div className="absolute bottom-0 left-0 w-64 h-64 bg-yellow-500 rounded-full filter blur-3xl opacity-5 transform -translate-x-1/2 translate-y-1/2"></div>
      
      {/* Wave separator */}
      <div className="absolute top-0 left-0 w-full">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 100" className="w-full h-auto fill-white">
          <path d="M0,64L80,69.3C160,75,320,85,480,80C640,75,800,53,960,48C1120,43,1280,53,1360,58.7L1440,64L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path>
        </svg>
      </div>
      
      {/* Main Footer Content */}
      <div className="container mx-auto px-4 py-16 pt-24 relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          {/* Column 1: Logo and About */}
          <div className="space-y-6 animate-fade-in">
            <Link href="/" className="inline-block group">
              <h5 className="uppercase tracking-widest font-bold text-3xl mb-2 shadow-glow group-hover:animate-pulse-slow">
                <span className="bg-gradient-to-r from-blue-400 to-blue-600 bg-clip-text text-transparent">vidya</span>
                <span className="text-yellow">s</span>
                <span className="bg-gradient-to-r from-blue-400 to-blue-600 bg-clip-text text-transparent">tu</span>
              </h5>
            </Link>
            <p className="text-sm text-gray-300 max-w-xs leading-relaxed">
              Online learning platform empowering minds, offering interactive
              courses from data science to web dev, boosting skills for career
              success.
            </p>
            <div className="space-y-3">
              <h4 className="text-lg font-semibold text-black">Download Our App</h4>
              <Link 
                href="https://play.google.com/store/apps/details?id=com.sisyphusinfotech.vidyastu"
                className="inline-block transform transition-transform hover:scale-105 hover:drop-shadow-xl"
              >
                <Image
                  src="/images/google-play-store.svg"
                  width={180}
                  height={60}
                  alt="Download Vidyastu app on Google Play"
                  className="rounded-lg shadow-lg"
                />
              </Link>
            </div>
          </div>

          {/* Column 2: Quick Links */}
          <div className="space-y-6 animate-fade-in" style={{ animationDelay: "0.2s" }}>
            <h4 className="text-lg font-semibold text-black relative inline-block pb-2 after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-12 after:h-1 after:bg-gradient-to-r after:from-blue-500 after:to-blue-700 after:rounded-full">
              Quick Links
            </h4>
            <ul className="space-y-3 grid grid-cols-1 gap-2">
              {footerNavs[0].items.map((item, idx) => (
                <li key={idx} className="text-sm">
                  <Link 
                    href={item.href}
                    className="text-gray-300 hover:text-blue-400 transition-colors duration-300 flex items-center group"
                  >
                    <span className="mr-2 text-blue-500 transform transition-transform group-hover:translate-x-1">→</span>
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Column 3: Contact and Subscribe */}
          <div className="space-y-6 animate-fade-in" style={{ animationDelay: "0.4s" }}>
            <div>
              <h4 className="text-lg font-semibold text-black relative inline-block pb-2 after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-12 after:h-1 after:bg-gradient-to-r after:from-blue-500 after:to-blue-700 after:rounded-full mb-4">
                Contact Us
              </h4>
              <div className="space-y-4">
                <p className="flex items-start text-sm text-gray-300 group">
                  <FaMapMarkerAlt className="mr-3 text-blue-400 mt-1 group-hover:text-blue-300 transition-colors" />
                  <span className="flex-1">74, Rastraguru Ave, Clive House, Ramgarh, Kolkata, West Bengal 700028</span>
                </p>
                <p className="flex items-center text-sm text-gray-300 group">
                  <FaEnvelope className="mr-3 text-blue-400 group-hover:text-blue-300 transition-colors" />
                  <a href="mailto:official@vidyastu.in" className="hover:text-blue-400 transition-colors">official@vidyastu.in</a>
                </p>
                <p className="flex items-center text-sm text-gray-300 group">
                  <FaPhone className="mr-3 text-blue-400 group-hover:text-blue-300 transition-colors" />
                  <a href="tel:+919051977167" className="hover:text-blue-400 transition-colors">+91-9051977167</a>
                </p>
              </div>
            </div>
            
            <div>
              <h4 className="text-lg font-semibold text-black relative inline-block pb-2 after:content-[''] after:absolute after:bottom-0 after:left-0 after:w-12 after:h-1 after:bg-gradient-to-r after:from-blue-500 after:to-blue-700 after:rounded-full mb-4">
                Stay Updated
              </h4>
              <form onSubmit={handleSubmit(submitHandler)} className="space-y-3">
                <div className="flex items-center relative overflow-hidden rounded-lg shadow-lg">
                  <input
                    type="email"
                    placeholder="Enter Your Email"
                    className="w-full p-3 bg-gray-800/50 border border-gray-700 text-white outline-none focus:border-blue-500 placeholder:text-gray-500 rounded-l-lg transition-all duration-300"
                    {...register("email", { required: true })}
                  />
                  <button 
                    type="submit"
                    disabled={disable}
                    className="p-3 text-white bg-gradient-to-r from-blue-600 to-blue-800 hover:from-blue-700 hover:to-blue-900 transition-all duration-300 font-medium rounded-r-lg min-w-[100px]"
                  >
                    Subscribe
                  </button>
                </div>
                {errors.email && <p className="text-red-400 text-xs">Email is required</p>}
              </form>
            </div>
            
            <div className="pt-2">
              <div className="rounded-lg overflow-hidden shadow-lg border border-gray-800 transition-transform hover:scale-[1.01] duration-300">
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3682.730398522733!2d88.41310387511618!3d22.626540679453377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89e10d12097a7%3A0xa74122e5f4a78073!2s74%2C%20Rastraguru%20Ave%2C%20Clive%20House%2C%20Ramgarh%2C%20Kolkata%2C%20West%20Bengal%20700028!5e0!3m2!1sen!2sin!4v1719408263747!5m2!1sen!2sin" 
                  className="w-full h-40"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Vidyastu Location Map"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="border-t border-gray-800/50 bg-black/80 backdrop-blur-sm relative z-10">
        <div className="container mx-auto px-4 py-6">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="text-sm text-white mb-4 md:mb-0 text-center md:text-left">
              &copy; 2024 Vidyastu All Rights Reserved. <br />
              <span className="text-xs">Designed & Developed by <Link href="https://sisyphusinfotech.com/" target="_blank" className="text-white hover:text-blue-300 transition-colors">Sisyphus Infotech</Link></span>
            </div>
            
            <div className="flex items-center space-x-4">
              <Link
                href="https://www.youtube.com/@vidyastu1787"
                target="_blank"
                aria-label="Youtube"
                className="w-9 h-9 flex items-center justify-center rounded-full bg-gray-800/80 hover:bg-red-600 transition-all duration-300 transform hover:scale-110 shadow-md"
              >
                <FaYoutube className="text-white" />
              </Link>
              <Link 
                href="https://api.whatsapp.com/send/?phone=919051977167&text=%22I%20am%20from%20your%20website.%22&type=phone_number&app_absent=0" 
                aria-label="Whatsapp"
                className="w-9 h-9 flex items-center justify-center rounded-full bg-gray-800/80 hover:bg-green-600 transition-all duration-300 transform hover:scale-110 shadow-md"
              >
                <IoLogoWhatsapp className="text-white" />
              </Link>
              <Link
                href="https://www.facebook.com/vidyastueducation"
                target="_blank"
                aria-label="Facebook"
                className="w-9 h-9 flex items-center justify-center rounded-full bg-gray-800/80 hover:bg-blue-600 transition-all duration-300 transform hover:scale-110 shadow-md"
              >
                <FaFacebook className="text-white" />
              </Link>
            </div>
            
            <div className="mt-4 md:mt-0">
              <ScrollToTop />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
