"use client";

import { Disclosure, Menu } from "@headlessui/react";
import { AiOutlineMenuFold, AiOutlineCloseCircle } from "react-icons/ai";
import { HiChevronDown } from "react-icons/hi";
import Link from "next/link";
import Image from "next/image";
import { usePathname } from "next/navigation";
import { MdOutlineMailOutline } from "react-icons/md";
import {
  FaFacebookSquare,
  FaInstagram,
  FaLink,
  FaLinkedin,
  FaPhoneAlt,
  FaYoutube,
} from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaCode, FaJava, FaPaintBrush, FaPen, FaChartLine, FaDatabase, FaChartBar, FaShieldAlt } from "react-icons/fa";

const navigation = [
  { name: "Home", href: "/", current: true },
  // { name: "Bengali Dictionary", href: "/bengali-dictionary/", current: false },
  { name: "Premium Courses", href: "/premium-courses/", current: false },
  { name: "Resourses", href: "/free-courses/", current: false },
  // { name: "Event", href: "/events/", current: false },
  { name: "About", href: "/about/", current: false },
  { name: "Blog", href: "/blog/", current: false },
  { name: "Careers", href: "/career/", current: false },
  { name: "Contact Us", href: "/contact/", current: false },
];

const premiumCourses = [
  { 
    name: "MERN Stack Web Development", 
    href: "/premium-courses/mern-stack-web-development",
    icon: <FaCode className="w-5 h-5" />,
    description: "Master full-stack development with MongoDB, Express, React & Node.js"
  },
  { 
    name: "Java Spring Boot Development", 
    href: "/premium-courses/full-stack-web-development-with-java-spring-boot",
    icon: <FaJava className="w-5 h-5" />,
    description: "Build enterprise applications with Java Spring Boot"
  },
  { 
    name: "UX Design", 
    href: "/premium-courses/uxperience-redefining-graphics-and-interface-design",
    icon: <FaPaintBrush className="w-5 h-5" />,
    description: "Create beautiful and functional user interfaces"
  },
  { 
    name: "Content Writing", 
    href: "/premium-courses/academic-non-academic-and-news-content-writing",
    icon: <FaPen className="w-5 h-5" />,
    description: "Master the art of engaging content creation"
  },
  { 
    name: "Digital Marketing", 
    href: "/premium-courses/digital-marketing-using-generative-ai-tools",
    icon: <FaChartLine className="w-5 h-5" />,
    description: "Learn modern digital marketing strategies with AI"
  },
  { 
    name: "Data Science & Analytics", 
    href: "/premium-courses/data-science-and-analytics-using-ai-and-chat-gpt",
    icon: <FaDatabase className="w-5 h-5" />,
    description: "Analyze data and build AI/ML models"
  },
  { 
    name: "Mutual Fund & Trading", 
    href: "/premium-courses/mutual-fund-share-market-and-trading",
    icon: <FaChartBar className="w-5 h-5" />,
    description: "Learn investment strategies and market analysis"
  },
  { 
    name: "Cyber Security", 
    href: "/premium-courses/cyber-security",
    icon: <FaShieldAlt className="w-5 h-5" />,
    description: "Master cybersecurity and ethical hacking"
  }
];

const quickLinks = [
  {
    name: "Purchase Course",
    href: "https://vidyastu.online/",
    icon: <FaLink className="w-4 h-4" />,
    isExternal: true
  },
  {
    name: "Download App",
    href: "https://play.google.com/store/apps/details?id=com.sisyphusinfotech.vidyastu",
    icon: <Image src="/images/google-play-icon.svg" width={15} height={15} alt="Google Play Store Icon" />,
    isExternal: true
  }
];

const Navbar = () => {
  const routerPath = usePathname(false);

  return (
    <div className="bg-gradient-to-r from-white to-blue-50 shadow-md sticky top-0 z-[10000]">
      <Disclosure
        as="nav"
        className="mx-auto py-2 xm:px-2 md:px-4 lg:px-4 container"
      >
        {({ open }) => (
          <>
            <div className="mb-2 pb-2 flex flex-col lg:flex-row justify-between items-center border-b border-blue-100">
              <div className="flex flex-row justify-center items-center space-x-4">
                <p className="flex flex-row items-center text-xs gap-2 mr-2 text-textDark hover:text-colorBrand transition-colors">
                  <MdOutlineMailOutline size={12} className="text-colorBrand" />
                  <span className="font-medium">Email:</span> official@vidyastu.in
                </p>
                <span className="text-blue-200">|</span>
                <p className="flex flex-row items-center text-xs gap-2 text-textDark hover:text-colorBrand transition-colors">
                  <FaPhoneAlt size={10} className="text-colorBrand" />
                  <span className="font-medium">Call:</span>
                  <Link href="tel:+919051977167" className="hover:underline">+91-9051977167</Link>
                </p>
              </div>
              <div className="flex flex-row justify-center items-center mt-2 lg:mt-0">
                <p className="text-xs text-textDark mr-3">Follow Us:</p>
                <div className="flex flex-row items-center space-x-3">
                  <Link href="https://www.facebook.com/vidyastueducation" target="_blank" 
                    className="p-1.5 bg-white rounded-full shadow-sm hover:shadow-md transition-all duration-300 hover:-translate-y-1">
                    <FaFacebookSquare
                      size={16}
                      className="text-blue-600"
                    />
                  </Link>
                  <Link href="https://www.youtube.com/@vidyastu1787" target="_blank" 
                    className="p-1.5 bg-white rounded-full shadow-sm hover:shadow-md transition-all duration-300 hover:-translate-y-1">
                    <FaYoutube size={16} className="text-red-500" />
                  </Link>
                  <Link href="https://wa.me/919051977167" target="_blank" 
                    className="p-1.5 bg-white rounded-full shadow-sm hover:shadow-md transition-all duration-300 hover:-translate-y-1">
                    <IoLogoWhatsapp size={16} className="text-green-500" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="flex md:flex-row md:gap-y-7 items-center justify-between">
                <Link
                  href="/"
                  className="md:flex md:flex-col md:items-center md:justify-start group"
                >
                  {/* <Image
                    src="/assets/logo/logo_transparent.png"
                    width={200}
                    height={150}
                    alt="vidyastu logo"
                    className="ml-2 md:ml-0"
                  /> */}
                  <h5 className="ml-2 md:ml-0 uppercase tracking-widest gradient-text font-bold text-3xl transition-all duration-300 group-hover:scale-105">vidya<span className="text-yellow">s</span>tu</h5>
                  <p className="mt-2 text-xs tracking-widest uppercase text-textDark font-semibold ml-2 md:ml-0">
                    Your Online Finishing School
                  </p>
                </Link>
                {/* mobile menu */}
                <div className="block items-center lg:hidden">
                  {/* mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-colorBrand hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-colorBrand transition-colors">
                    <span className="sr-only">Open Main Menu</span>
                    {open ? (
                      <AiOutlineCloseCircle
                        size={30}
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    ) : (
                      <AiOutlineMenuFold
                        size={30}
                        className="block h-6 w-6"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>
                {/* desktop menu items */}
                <div className="hidden lg:flex flex-row space-x-3 items-center justify-between">
                  {navigation.map((item) => {
                    if (item.name === "Premium Courses") {
                      return (
                        <Menu as="div" className="relative" key={item.name}>
                          <Menu.Button
                            className={`${
                              routerPath === item.href
                                ? "text-colorBrand border-b-2 border-colorBrand"
                                : "text-textDark"
                            } px-3 py-2 rounded-lg text-sm font-medium hover:bg-blue-50 group flex items-center gap-1 transition-all duration-300`}
                          >
                            {item.name}
                            <HiChevronDown className="w-4 h-4 group-hover:rotate-180 transition-transform duration-300" />
                          </Menu.Button>
                          <Menu.Items className="absolute left-0 mt-2 w-[480px] origin-top-left bg-white divide-y divide-gray-100 rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none z-50 overflow-hidden">
                            <div className="p-2">
                              <div className="bg-gradient-to-r from-blue-600 to-teal-500 text-white p-4 rounded-lg mb-2">
                                <h3 className="font-semibold text-lg mb-1">Premium Courses</h3>
                                <p className="text-sm text-blue-100">Explore our industry-leading courses</p>
                              </div>
                              <div className="grid grid-cols-2 gap-2">
                                {premiumCourses.map((course) => (
                                  <Menu.Item key={course.name}>
                                    {({ active }) => (
                                      <Link
                                        href={course.href}
                                        className={`${
                                          active ? 'bg-blue-50 text-colorBrand' : 'text-textDark'
                                        } group flex flex-col rounded-lg p-3 transition-all duration-200 hover:shadow-md`}
                                      >
                                        <div className="flex items-center gap-3">
                                          <span className={`${
                                            active ? 'bg-blue-100 text-colorBrand' : 'bg-blue-50 text-textDark'
                                          } p-2 rounded-lg transition-colors duration-200`}>
                                            {course.icon}
                                          </span>
                                          <span className="font-medium">{course.name}</span>
                                        </div>
                                        <p className="text-sm text-textMedium mt-1 ml-11">
                                          {course.description}
                                        </p>
                                      </Link>
                                    )}
                                  </Menu.Item>
                                ))}
                              </div>
                            </div>
                          </Menu.Items>
                        </Menu>
                      );
                    }
                    return (
                      <Link
                        key={item.name}
                        href={item.href}
                        className={`${
                          routerPath === item.href
                            ? "text-colorBrand border-b-2 border-colorBrand"
                            : "text-textDark"
                        } px-3 py-2 rounded-lg text-sm font-medium hover:bg-blue-50 transition-all duration-300`}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    );
                  })}
                  
                  {/* Quick Links Dropdown */}
                  <Menu as="div" className="relative">
                    <Menu.Button
                      className="px-4 py-2 rounded-full text-sm font-medium bg-gradient-to-r from-blue-600 to-teal-500 text-white hover:shadow-lg transition-all duration-300 transform hover:scale-105 flex items-center gap-2"
                    >
                      Quick Links
                      <HiChevronDown className="w-4 h-4 group-hover:rotate-180 transition-transform duration-300" />
                    </Menu.Button>
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right bg-white divide-y divide-gray-100 rounded-lg shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none z-50 border border-blue-100">
                      <div className="py-2">
                        {quickLinks.map((link) => (
                          <Menu.Item key={link.name}>
                            {({ active }) => (
                              <Link
                                href={link.href}
                                target={link.isExternal ? "_blank" : undefined}
                                className={`${
                                  active ? 'bg-gradient-to-r from-blue-50 to-blue-100 text-colorBrand' : 'text-textDark'
                                } group flex items-center px-4 py-3 text-sm hover:bg-blue-50 transition-all duration-300 relative`}
                              >
                                <span className={`${active ? 'scale-110' : 'scale-100'} transition-transform duration-300 flex items-center gap-3`}>
                                  {link.icon}
                                  {link.name}
                                </span>
                                {active && (
                                  <span className="absolute right-4 text-colorBrand">
                                    →
                                  </span>
                                )}
                              </Link>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </Menu.Items>
                  </Menu>
                </div>
              </div>
              {/* mobile menu items  */}
              <Disclosure.Panel className="lg:hidden max-h-[calc(100vh-200px)] overflow-y-auto">
                <div className="p-6 space-y-4">
                  {navigation.map((item) => {
                    if (item.name === "Premium Courses") {
                      return (
                        <Disclosure as="div" className="space-y-2" key={item.name}>
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={`${
                                  routerPath === item.href
                                    ? "text-colorBrand"
                                    : "text-textDark hover:bg-blue-50"
                                } w-full px-3 py-2 rounded-lg text-base font-medium flex items-center justify-between group transition-all duration-300`}
                              >
                                <div className="flex items-center gap-2">
                                  <span className="p-1.5 bg-gradient-to-r from-blue-100 to-blue-50 rounded-lg group-hover:scale-110 transition-transform duration-300">
                                    <FaCode className="w-4 h-4 text-colorBrand" />
                                  </span>
                                  {item.name}
                                </div>
                                <HiChevronDown 
                                  className={`w-4 h-4 transition-transform duration-300 ${
                                    open ? 'rotate-180 text-colorBrand' : ''
                                  }`} 
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="space-y-3 px-2 overflow-y-auto">
                                <div className="bg-gradient-to-r from-blue-600 to-teal-500 text-white p-4 rounded-xl mb-3 mx-2 transform transition-all duration-500 hover:scale-[1.02] hover:shadow-lg">
                                  <h3 className="font-semibold text-lg mb-1">Premium Courses</h3>
                                  <p className="text-sm text-blue-100">Explore our industry-leading courses</p>
                                </div>
                                <div className="grid grid-cols-1 gap-3 pb-4">
                                  {premiumCourses.map((course) => (
                                    <Link href={course.href} key={course.name}>
                                      <div className="block mx-2 p-4 text-sm bg-white hover:bg-blue-50 hover:text-colorBrand rounded-xl transition-all duration-300 space-y-2 border border-gray-100 hover:border-blue-100 hover:shadow-md transform hover:-translate-y-1">
                                        <div className="flex items-center gap-3">
                                          <span className="p-2.5 bg-gradient-to-br from-blue-50 to-white rounded-lg shadow-sm group-hover:shadow">
                                            {course.icon}
                                          </span>
                                          <span className="font-medium text-textDark">{course.name}</span>
                                        </div>
                                        <p className="text-sm text-textMedium ml-12">
                                          {course.description}
                                        </p>
                                        <div className="ml-12 flex items-center text-xs text-colorBrand">
                                          <span>Learn more</span>
                                          <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                          </svg>
                                        </div>
                                      </div>
                                    </Link>
                                  ))}
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      );
                    }
                    return (
                      <Link href={item.href} key={item.name}>
                        <div
                          className={`${
                            routerPath === item.href
                              ? "text-colorBrand"
                              : "text-textDark hover:bg-blue-50"
                          } block px-3 py-2 rounded-lg text-base font-medium transition-colors`}
                        >
                          {item.name}
                        </div>
                      </Link>
                    );
                  })}
                  
                  {/* Quick Links in mobile menu */}
                  <div className="mt-6 space-y-4">
                    <div className="px-3 py-2 bg-gradient-to-r from-blue-600 to-teal-500 text-white rounded-lg">
                      <h3 className="text-sm font-semibold mb-3">Quick Links</h3>
                      <div className="space-y-3">
                        {quickLinks.map((link) => (
                          <Link 
                            key={link.name} 
                            href={link.href} 
                            target={link.isExternal ? "_blank" : undefined}
                          >
                            <div className="flex items-center gap-3 px-3 py-2.5 bg-white/10 backdrop-blur-sm rounded-lg hover:bg-white/20 transition-all duration-300">
                              <span className="p-1.5 bg-white/20 rounded-full">
                                {link.icon}
                              </span>
                              <span className="text-sm font-medium">{link.name}</span>
                              {link.isExternal && (
                                <svg className="w-4 h-4 ml-auto" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                </svg>
                              )}
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Disclosure.Panel>
            </div>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default Navbar;
