"use client";

import { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './globals.css';
import Navbar from '@/components/layout/Navbar';
import Footer from '@/components/layout/Footer';
import { usePathname } from 'next/navigation';

gsap.registerPlugin(ScrollTrigger);

export default function RootLayout({ children }) {
  const pathname = usePathname();

  useEffect(() => {
    // Reset scroll position on route change
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    // Add page transition animations
    const pageTransition = () => {
      const tl = gsap.timeline();
      
      tl.to("body > div", {
        opacity: 0,
        y: 20,
        duration: 0.3,
        ease: "power2.inOut"
      }).to("body > div", {
        opacity: 1,
        y: 0,
        duration: 0.3,
        ease: "power2.out"
      });
    };

    // Run page transition on route change
    window.addEventListener('routeChangeStart', pageTransition);

    return () => {
      window.removeEventListener('routeChangeStart', pageTransition);
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <html lang="en">
      <body>
        <Navbar />
        <main className="min-h-screen">
          {children}
        </main>
        <Footer />
      </body>
    </html>
  );
} 